import React, { useState, useEffect } from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';
import axios from 'axios';
import { stakers } from './stakers';
import { owners } from './owners';
import './App.css';
import './web3modalConfig';
import Cookies from 'js-cookie';
import {
  Stepper, Step, StepLabel, Button, Typography, Container, Box, TextField, ThemeProvider, Fade
} from '@mui/material';
import theme from './theme'; // Import the custom theme

Cookies.set('cookieName', 'cookieValue', { sameSite: 'None', secure: true });

const steps = [
  'Connect your Wallet',
  'Verify Ownership',
  'Input Address',
  'Confirmation'
];

const App = () => {
  const { address, isConnected } = useWeb3ModalAccount();
  const [message, setMessage] = useState('');
  const [verified, setVerified] = useState(false);
  const [inputAddress, setInputAddress] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isConnected && activeStep === 0) {
      handleNext();
    }
  }, [isConnected, activeStep]);

  console.log("Connected Successfully: ", address);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const verifyOwnership = async () => {
    if (!isConnected) {
      setMessage('Please connect your wallet first.');
      return;
    }

    const normalizedAddress = address.toLowerCase();
    const normalizedStakers = stakers.map(addr => addr.toLowerCase());
    const normalizedOwners = owners.map(addr => addr.toLowerCase());

    if (normalizedStakers.includes(normalizedAddress) || normalizedOwners.includes(normalizedAddress)) {
      try {
        const messageToSign = "Please sign this message to verify your wallet ownership.";
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const signature = await signer.signMessage(messageToSign);

        console.log('Signature:', signature);

        // Send the signature to the backend for verification
        const response = await axios.post('https://aces-backend-cc6161264ac6.herokuapp.com/verify-signature', {
          connectedWallet: address,
          signature,
          message: messageToSign
        });

        if (response.data.verified) {
          if (response.data.registered) {
            setMessage('You are already registered!');
          } else {
            setVerified(true);
            handleNext();
          }
        } else {
          setMessage('Failed to verify ownership. Please try again.');
        }
      } catch (error) {
        console.error('Signature request failed', error);
        setMessage('Failed to verify signature. Please try again.');
      }
    } else {
      setMessage('You are not an ACES holder.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!verified) {
      setMessage('You must verify ownership before submitting the form.');
      return;
    }

    try {
      await axios.post('https://aces-backend-cc6161264ac6.herokuapp.com/submit', {
        connectedWallet: address,
        inputAddress: inputAddress
      });
      setMessage('Form submitted successfully!');
      handleNext();
    } catch (error) {
      console.error('Form submission failed', error);
      setMessage(error.response?.data || 'Failed to submit the form. Please try again.');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fade in={true}>
            <div>
              <Typography>Connect your Wallet</Typography>
              <w3m-button></w3m-button>
            </div>
          </Fade>
        );
      case 1:
        return (
          <Fade in={true}>
            <div>
              <Typography>We need to verify that you are the true owner of this wallet by signing a message via your wallet.</Typography>
              <Button variant="contained" onClick={verifyOwnership} sx={{ mt: 2 }}>VERIFY</Button>
              <Typography sx={{ mt: 2 }}>{message}</Typography>
            </div>
          </Fade>
        );
      case 2:
        return (
          <Fade in={true}>
            <div>
              <form onSubmit={handleSubmit}>
                <Typography>You can now input the Solana wallet address you want all your ACEs NFT to be airdropped to.</Typography>
                <TextField
                  label="Input Wallet Address"
                  value={inputAddress}
                  onChange={(e) => setInputAddress(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2, mb: 2 }}
                />
                <Button variant="contained" type="submit">SEND</Button>
                <Typography sx={{ mt: 2 }}>{message}</Typography>
              </form>
            </div>
          </Fade>
        );
      case 3:
        return (
          <Fade in={true}>
            <div>
              <Typography>You have been successfully registered. See you on the other side!</Typography>
              <Button 
                variant="contained" 
                href="https://theaces.xyz" 
                sx={{ mt: 2, backgroundColor: '#575757', color: '#fff', border: '2px solid #f5f5dc', borderRadius: '20px', fontWeight: 500, fontSize: '20px' }}
              >
                Return to Website
              </Button>
            </div>
          </Fade>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <img src="https://theaces.xyz/images/the-aces-full-mark.svg" alt="Logo" className="App-logo" />
          <nav className={`App-nav ${menuOpen ? 'open' : ''}`}>
            <a href="https://theaces.xyz/">Back to Main</a>
            <a href="https://theaces.xyz/about">About</a>
            <w3m-button></w3m-button>
          </nav>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
        </header>
        <div className="App-container">
          <h1>MOVE TO SOLANA</h1>
          <Container>
            <Box sx={{ width: '100%', mt: 4 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{
                      completed: activeStep > index,
                      active: activeStep === index
                    }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ mt: 2, mb: 2 }}>
                {renderStepContent(activeStep)}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
