// src/web3modalConfig.js
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

// 1. Get projectId
const projectId = '75d5539c3570f40e98d2f4134d9025c5';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
};

// 3. Create a metadata object
const metadata = {
  name: 'ACES Staking Verifier',
  description: 'Verify ownership of ACES NFTs',
  url: 'https://movetosolana.theaces.xyz', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableEmail: true,
  enableCoinbase: true,
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export { ethersConfig };
